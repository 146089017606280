.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Assure que les enfants ne débordent pas */
}

.hero {
  background-image: url("../../../../public/images/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
  z-index: -1; /* Restez avec -1 pour l'arrière-plan */
}

.container {
  position: relative;
  z-index: 1; /* Assurez-vous que le conteneur a un z-index positif */
}

.hero #heading h3 {
  color: #fff;
}

.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

.button {
  position: relative;
  z-index: 2; /* Assurez-vous que les boutons et liens ont un z-index positif */
}

.primary-btn {
  position: relative;
  z-index: 2;
}

.primary-btn i {
  margin-left: 10px;
}

.external-link {
  display: inline-block;
  margin-top: 10px;
  position: relative;
  z-index: 2;
  color: #fff;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  position: relative;
  z-index: 2;
}

li {
  margin-top: 5px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }
}
